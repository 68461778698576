import { render, staticRenderFns } from "./Bussiness.vue?vue&type=template&id=513ce9b7&scoped=true&"
import script from "./Bussiness.vue?vue&type=script&lang=js&"
export * from "./Bussiness.vue?vue&type=script&lang=js&"
import style0 from "./Bussiness.vue?vue&type=style&index=0&id=513ce9b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513ce9b7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Template: require('/devcloud/slavespace/slave1-new/workspace/j_52qd1tlN/src/components/FilterSearch/template/index.js').default})
